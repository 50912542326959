<template>
  <template v-if="readOnly"> {{ getTextLabel(valueSelect) }} </template>
  <a-select
    v-else
    v-model:value="valueSelect"
    :mode="mode"
    style="width: 100%"
    :disabled="disabled"
    :placeholder="placeholder"
    :showSearch="showSearch"
    :filterOption="filterOption"
    :maxTagCount="maxTagCount"
    :max-tag-text-length="maxTagTextLength"
    :options="listOption"
    :allowClear="allowClear"
    size="medium"
    @change="change"
  ></a-select>
</template>
<script>
import { computed, defineComponent, ref, watch } from 'vue';
import VueTypes from 'vue-types';
import { getText } from '@/util/common-utils';
import _ from 'lodash';

export default defineComponent({
  name: 'Select',
  props: {
    options: VueTypes.array.def([]),
    disabled: VueTypes.bool.def(false),
    labelProp: VueTypes.string.def('label'),
    valueProp: VueTypes.string.def('value'),
    maxTagCount: VueTypes.number.def(3),
    maxTagTextLength: VueTypes.number.def(30),
    multiple: VueTypes.bool.def(false),
    placeholder: VueTypes.string,
    allowClear: VueTypes.bool.def(false),
    value: VueTypes.oneOfType([
      VueTypes.string,
      VueTypes.number,
      VueTypes.array,
    ]),
    readOnly: VueTypes.bool.def(false),
    isAll: VueTypes.bool.def(false),
    showSearch: VueTypes.bool.def(true),
  },
  setup(props, { emit }) {
    const listOption = computed(() => {
      const data = _.cloneDeep(props.options);
      if (props.isAll) {
        data.unshift({
          value: null,
          label: 'Tất cả',
        });
      }
      return data.map((e) => ({
        label: e[props.labelProp],
        value: e[props.valueProp],
      }));
    });
    const filterOption = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    const valueSelect = ref(props.value);
    const change = (val) => emit('update:value', val);
    const mode = computed(() => (props.multiple ? 'multiple' : null));
    watch(
      () => props.value,
      (val) => (valueSelect.value = val)
    );
    const getTextLabel = computed(() => (value) => {
      if (!props.multiple) {
        return getText(value, listOption.value);
      }
      return value.map((e) => getText(e, listOption.value)).join(', ');
    });
    return {
      valueSelect,
      listOption,
      filterOption,
      change,
      mode,
      getTextLabel,
    };
  },
});
</script>
