export const MESSAGES = {
  MESSAGE_001: 'Duyệt thành công',
  MESSAGE_002: 'Duyệt thất bại',
  MESSAGE_003: 'Xóa thành công',
  MESSAGE_004: 'Xóa thất bại',
  MESSAGE_005: 'Tạo thành công',
  MESSAGE_006: 'Tạo thất bại',
  MESSAGE_007: 'Sửa thành công',
  MESSAGE_008: 'Sửa thất bại',
  MESSAGES_009: 'Ẩn thành công',
  MESSAGES_0010: 'Ẩn thất bại',
  MESSAGES_0011: 'Hiện thị thất bại',
  MESSAGES_0012: 'Hiện thị thành công',
  MESSAGES_0013: 'Bạn có muốn hiển thị không ?',
  MESSAGES_0014: 'Bạn có muốn ẩn không ?',
  MESSAGES_0015: 'Bạn có chắc chắn muốn lưu không?',
  MESSAGES_0016: 'Không có bản ghi được chọn',
  MESSAGES_0017: 'Bạn có chắc chắn muốn xóa không?',
  MESSAGES_0018: 'Bạn có chắc chắn muốn hủy không?',
  MESSAGES_0019: 'Hủy thành công',
};
export const MESSAGE_DATA_APPROVAL = {
  DATA_APPROVAL_001: 'Bạn có chắc chắn muốn duyệt không?',
};
